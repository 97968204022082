import { Clip } from '@air/api/types';
import { FileExtensionPreview, getFilename } from '@air/component-file-extension-preview';

interface DndFileDragPreviewProps {
  file: Clip;
}

export const DNDFileGalleryCardDragPreview = ({ file }: DndFileDragPreviewProps) => (
  <div className="flex size-full flex-col justify-between rounded bg-white p-3">
    <div className="flex size-8 shrink-0 items-center justify-center rounded bg-pigeon-100">
      <FileExtensionPreview ext={file.ext} />
    </div>
    <span className={'pointer-events-auto truncate font-medium'}>{getFilename(file)}</span>
  </div>
);
