import {
  FileStatusButton,
  FileStatusIconProgressing,
  FileStatusSubtitle,
  FileStatusTitle,
} from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import { formatDistance } from 'date-fns';
import { isNumber } from 'lodash';
import pluralize from 'pluralize';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { GetInProgressMetadata, ZippingTask } from '~/store/tasks/types';

export type ZippingTaskInProgressPanelItemProps = {
  metadata: GetInProgressMetadata<ZippingTask>;
  onCancel: () => void;
};

export const ZippingTaskInProgressPanelItem = memo(
  ({ onCancel, metadata: { clipIds = [], boardIds = [], secondsRemaining } }: ZippingTaskInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<FileStatusIconProgressing />}
        title={
          <FileStatusTitle data-testid="ZIPPING_PANEL_IN_PROGRESS_TITLE">{`Zipping ${pluralize(
            'item',
            clipIds.length + boardIds.length,
            true,
          )}`}</FileStatusTitle>
        }
        subtitle={
          <FileStatusSubtitle className="truncate">
            {isNumber(secondsRemaining)
              ? `${formatDistance(new Date(Date.now() + secondsRemaining * 1000), new Date(), {
                  includeSeconds: true,
                })} left`
              : `Your download will begin shortly.`}
          </FileStatusSubtitle>
        }
        buttons={
          <FileStatusButton label="Cancel" onClick={() => onCancel()}>
            <Close className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

ZippingTaskInProgressPanelItem.displayName = 'ZippingInProgressPanelItem';
