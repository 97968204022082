import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { InlineInput } from '@air/component-inline-input';
import { Plus } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { tailwindMerge } from '@air/tailwind-variants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';

import { PrivateBoardSearchHeader } from '~/components/BoardSearch/PrivateBoardSearchHeader';
import { useLibraries } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { SimpleSearchBar } from '~/components/SimpleSearchBar';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useCreateNewBoard } from '~/swr-hooks/boards/useCreateNewBoard';
import { useRootWorkspaceBoards } from '~/swr-hooks/boards/useRootWorkspaceBoards';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

import { BoardListItemProps, BoardSearchListItem } from './BoardSearchListItem';
import { LibraryListItem, LibraryListItemProps } from './LibraryListItem';
import { getLibraryBoards, getUpdatedLibraryBoards } from './util';

export type BoardSearchOption = Pick<Board, 'id' | 'title' | 'ancestors' | 'thumbnails' | 'library'>;
export type LibraryBoardsObejct = Record<string, Board[]>;

export interface PrivateBoardSearchProps
  extends Pick<
      BoardListItemProps,
      'getIsBoardDisabled' | 'initialBoardId' | 'isBoardSelected' | 'shouldShowSubBoards' | 'onBoardSelectChange'
    >,
    Pick<
      LibraryListItemProps,
      | 'isLibrarySelected'
      | 'shouldShowLibraryBoards'
      | 'onSelectLibrary'
      | 'shouldExpandLibraryInsteadOfSelect'
      | 'getIsLibraryDisabled'
    > {
  className?: string;
  children?: ReactNode;
}

export const PrivateBoardSearch = memo(
  ({
    className,
    initialBoardId,
    getIsBoardDisabled,
    isBoardSelected,
    onBoardSelectChange,
    shouldShowSubBoards,
    shouldShowLibraryBoards,
    shouldExpandLibraryInsteadOfSelect = false,
    getIsLibraryDisabled,
    onSelectLibrary,
    isLibrarySelected,
    children,
  }: PrivateBoardSearchProps) => {
    const { createNewBoard } = useCreateNewBoard();
    const { currentWorkspace } = useCurrentWorkspace();
    const { data: workspaceBoardsResponse } = useRootWorkspaceBoards(currentWorkspace?.id);
    const { generalLibraryTitle } = useGeneralLibraryTitle();
    const workspaceBoards = workspaceBoardsResponse || [];

    const { data: libraries } = useLibraries();
    const flattenedLibraries = useMemo(() => libraries?.pages.flatMap((page) => page.data), [libraries]);

    const [searchTerm, setSearchTerm] = useState('');
    const [availableBoards, setAvailableBoards] = useState<Board[]>([]);
    const [availableLibraryBoards, setAvailableLibraryBoards] = useState<LibraryBoardsObejct>({});
    const [isCreatingBoard, setIsCreatingBoard] = useState<boolean>(false);
    const librariesBoardsKeys = Object.keys(availableLibraryBoards);
    const { libraries: librariesFlag } = useFlags();
    const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
    const workspaceId = currentWorkspace?.id;

    const _availableLibraryBoards = Object.values(availableLibraryBoards).flat();

    useFetchObjectsPermissions({
      objects: {
        boardIds: [...availableBoards, ...workspaceBoards, ..._availableLibraryBoards].map((board) => board.id),
        libraryIds: flattenedLibraries?.map((library) => library.id),
      },
    });

    const handleTermChange = useCallback(
      async (search: string) => {
        try {
          if (!workspaceId) {
            throw new Error('No current workspace');
          }

          const { data: boards } = await Boards.list({
            limit: 10,
            includeAncestors: true,
            search,
            onlyEditable: true,
            workspaceId,
          });

          if (librariesFlag) {
            setAvailableBoards(boards.filter((b) => !b.library));
            setAvailableLibraryBoards(getLibraryBoards(boards));
          } else {
            setAvailableBoards(boards);
          }
        } catch (error) {
          reportErrorToBugsnag({
            error,
            context: 'FAiled to load boards in PrivateBoardSearch',
            metadata: {
              search,
            },
          });
        }
      },
      [librariesFlag, workspaceId],
    );

    const createBoard = useCallback(
      async (boardName: string) => {
        const newBoard = await createNewBoard({
          board: {
            title: boardName,
          },
          trackLocation: 'board-select-modal',
          shouldFollow: true,
        });
        if (!newBoard) return;
        setAvailableBoards((boards) => [newBoard, ...boards]);
        if (librariesFlag) {
          setAvailableLibraryBoards((prev) => getUpdatedLibraryBoards(prev, newBoard));
        }
        onBoardSelectChange({ board: newBoard, selected: true });
      },
      [createNewBoard, onBoardSelectChange, setAvailableBoards, setAvailableLibraryBoards, librariesFlag],
    );

    return (
      <div className={tailwindMerge('h-full', className)} data-testid="PRIVATE_BOARD_SEARCH">
        <div className="mb-3">
          <SimpleSearchBar
            onSearchTermChange={(term) => {
              setSearchTerm(term);
              handleTermChange(term);
            }}
            placeholder={`Search ${currentWorkspace?.name || ''}`}
            name="search-boards"
          />
        </div>
        <div className="workspace-boards h-[320px] overflow-y-auto rounded border border-grey-7 p-3">
          {searchTerm && (availableBoards || librariesBoardsKeys.length > 0) ? (
            <>
              {availableBoards?.length > 0 && librariesFlag && (
                <PrivateBoardSearchHeader
                  title={librariesFlag && generalLibraryTitle ? generalLibraryTitle : 'Workspace Boards'}
                />
              )}
              {availableBoards?.map((b) => (
                <BoardSearchListItem
                  key={b.id}
                  shouldShowSubBoards={shouldShowSubBoards}
                  isBoardSelected={isBoardSelected}
                  onBoardSelectChange={onBoardSelectChange}
                  board={b}
                  getIsBoardDisabled={getIsBoardDisabled}
                />
              ))}
              {librariesBoardsKeys.length > 0 && librariesFlag && (
                <div
                  style={{
                    marginTop: availableBoards.length > 0 ? 20 : 0,
                  }}
                >
                  <PrivateBoardSearchHeader title="Libraries" />
                </div>
              )}
              {librariesBoardsKeys.map((libraryId) => {
                const libraryBoards = availableLibraryBoards[libraryId];
                const library = libraryBoards[0].library;

                if (!library) return null;

                return (
                  <LibraryListItem
                    key={library.id}
                    library={library}
                    isLibrarySelected={isLibrarySelected}
                    onSelectLibrary={onSelectLibrary}
                    getIsLibraryDisabled={getIsLibraryDisabled}
                    shouldShowSubBoards={shouldShowSubBoards}
                    shouldShowLibraryBoards={() => true}
                    isBoardSelected={isBoardSelected}
                    onBoardSelectChange={onBoardSelectChange}
                    getIsBoardDisabled={getIsBoardDisabled}
                    initialBoardId={initialBoardId}
                    libraryBoards={libraryBoards}
                    shouldExpandLibraryInsteadOfSelect={shouldExpandLibraryInsteadOfSelect}
                  />
                );
              })}
            </>
          ) : (
            <>
              <PrivateBoardSearchHeader
                title={librariesFlag && generalLibraryTitle ? generalLibraryTitle : 'Workspace Boards'}
                rightAdornment={
                  canCreateBoard(workspacePermissions) && (
                    <Tooltip label="Create new board" side="right">
                      <IconButton
                        data-testid="PRIVATE_BOARD_SEARCH_CREATE_ROOT_BOARD_BUTTON"
                        icon={Plus}
                        onClick={() => setIsCreatingBoard(true)}
                        size="small"
                        disabled={isCreatingBoard}
                        appearance="ghost"
                        color="grey"
                        label="Create New Board"
                      />
                    </Tooltip>
                  )
                }
              />
              {isCreatingBoard && (
                <InlineInput
                  rows={1}
                  allowNewLines={false}
                  label="Board name"
                  className="mb-2"
                  inputClassName="w-full"
                  data-testid="PRIVATE_BOARD_SEARCH_CREATE_ROOT_BOARD_EDITABLE_TEXT"
                  onSubmit={async (boardName) => {
                    setIsCreatingBoard(false);
                    if (boardName) {
                      await createBoard(boardName);
                    }
                  }}
                />
              )}
              {workspaceBoards.map((b) => (
                <BoardSearchListItem
                  key={b.id}
                  initialBoardId={initialBoardId}
                  shouldShowSubBoards={shouldShowSubBoards}
                  isBoardSelected={isBoardSelected}
                  onBoardSelectChange={onBoardSelectChange}
                  board={b}
                  getIsBoardDisabled={getIsBoardDisabled}
                />
              ))}
              {librariesFlag && !!flattenedLibraries?.length && (
                <div className="mt-4">
                  <PrivateBoardSearchHeader title="Libraries" />
                  {flattenedLibraries.map((library) => (
                    <LibraryListItem
                      key={library.id}
                      library={library}
                      isLibrarySelected={isLibrarySelected}
                      getIsLibraryDisabled={getIsLibraryDisabled}
                      onSelectLibrary={onSelectLibrary}
                      shouldShowSubBoards={shouldShowSubBoards}
                      shouldShowLibraryBoards={shouldShowLibraryBoards}
                      isBoardSelected={isBoardSelected}
                      onBoardSelectChange={onBoardSelectChange}
                      getIsBoardDisabled={getIsBoardDisabled}
                      initialBoardId={initialBoardId}
                      shouldExpandLibraryInsteadOfSelect={shouldExpandLibraryInsteadOfSelect}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          {children}
        </div>
      </div>
    );
  },
);

PrivateBoardSearch.displayName = 'PrivateBoardSearch';
