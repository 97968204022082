import type { Board } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { memo } from 'react';

import { BoardSelectBoardListItem } from '~/components/BoardSelect/BoardSelectBoardListItem';
import { BoardSelectListItem } from '~/components/BoardSelect/components/BoardSelectListItem';
import { useLibraryRootBoards } from '~/components/LibraryBeta/hooks/queries/useLibraryRootBoards';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

type Library = Required<Board>['library'];

export type BoardSelectLibraryListItemProps = {
  canSelect?: boolean;
  getBoardDisabledMessage?: (board: Board) => string | undefined;
  getLibraryDisabledMessage?: (board: Library) => string | undefined;
  isBoardDisabled?: (board: Board) => boolean;
  isBoardExpanded?: (id: Board['id']) => boolean;
  isBoardSelected?: (board: Board) => boolean;
  isLibraryDisabled?: (library: Library) => boolean;
  isLibraryExpanded?: (library: Library) => boolean;
  isLibrarySelected?: (library: Library) => boolean;
  library: Library;
  onBoardOpenChange: (params: { id: Board['id']; isOpen?: boolean }) => void;
  onLibraryOpenChange: (params: { id: Library['id']; isOpen?: boolean }) => void;
  onSelectBoard?: (board: Board | null) => void;
  onSelectLibrary?: (library: Library | undefined) => void;
  search?: string;
  shouldShowSubBoards?: (id: Board['id']) => boolean;
};

export const BoardSelectLibraryListItem = memo(
  ({
    canSelect,
    isBoardDisabled,
    isBoardExpanded,
    isBoardSelected,
    getBoardDisabledMessage,
    getLibraryDisabledMessage,
    isLibraryDisabled,
    isLibraryExpanded,
    isLibrarySelected,
    library,
    onBoardOpenChange,
    onLibraryOpenChange,
    onSelectBoard,
    onSelectLibrary,
    search,
    shouldShowSubBoards,
  }: BoardSelectLibraryListItemProps) => {
    const isExpanded = isLibraryExpanded?.(library);
    const isDisabled = isLibraryDisabled?.(library);
    const isSelected = isLibrarySelected?.(library);

    const { currentWorkspace } = useCurrentWorkspace();
    const {
      data: boards = [],
      isLoadingMore,
      hasMore,
      loadNextPage,
    } = useLibraryRootBoards(currentWorkspace?.id, library.id, { search });

    useFetchObjectsPermissions({
      objects: {
        boardIds: boards.map((board) => board.id),
      },
    });

    return (
      <BoardSelectListItem
        disabled={isDisabled}
        disabledMessage={getLibraryDisabledMessage?.(library)}
        id={library.id}
        isExpanded={isExpanded}
        isSelected={canSelect ? isSelected : false}
        onOpenChange={(isOpen) => onLibraryOpenChange({ id: library.id, isOpen })}
        onSelect={() =>
          canSelect
            ? onSelectLibrary?.(isSelected ? undefined : library)
            : onLibraryOpenChange({ id: library.id, isOpen: !isExpanded })
        }
        prefix={
          <div
            className="flex size-6 items-center justify-center rounded bg-pigeon-50"
            style={{ backgroundColor: library.color?.backgroundHex }}
          >
            {library.icon ?? '📚'}
          </div>
        }
        title={library.title}
      >
        {boards.map((board) => {
          return (
            <BoardSelectBoardListItem
              board={board}
              getBoardDisabledMessage={getBoardDisabledMessage}
              isBoardDisabled={isBoardDisabled}
              isBoardExpanded={isBoardExpanded}
              isBoardSelected={isBoardSelected}
              key={board.id}
              onBoardOpenChange={onBoardOpenChange}
              onSelectBoard={onSelectBoard}
              shouldShowSubBoards={shouldShowSubBoards}
              search={search}
            />
          );
        })}

        {!isLoadingMore && hasMore && (
          <Button onClick={() => loadNextPage()} size="small" appearance="ghost" color="grey">
            Show more
          </Button>
        )}
      </BoardSelectListItem>
    );
  },
);

BoardSelectLibraryListItem.displayName = 'BoardSelectLibraryListItem';
