import type { Board } from '@air/api/types';
import { TreeItemProps } from '@air/component-tree';
import { Plus } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo, useCallback, useEffect, useState } from 'react';

import { BoardSelectCreateInput } from '~/components/BoardSelect/components/BoardSelectCreateInput';
import { BoardSelectListItem } from '~/components/BoardSelect/components/BoardSelectListItem';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCreateNewBoard } from '~/swr-hooks/boards/useCreateNewBoard';
import { useSubBoardsList } from '~/swr-hooks/boards/useSubBoardsList';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

const CREATE_SUB_BOARD_LABEL = 'Create New Sub-Board';

export type BoardSelectBoardListItemProps = Pick<TreeItemProps, 'open'> & {
  board: Board;
  getBoardDisabledMessage?: (board: Board) => string | undefined;
  isBoardDisabled?: (board: Board) => boolean;
  isBoardExpanded?: (id: Board['id']) => boolean;
  isBoardSelected?: (board: Board) => boolean;
  onBoardOpenChange: (params: { id: Board['id']; isOpen?: boolean }) => void;
  onSelectBoard?: (board: Board | null) => void;
  shouldShowSubBoards?: (id: Board['id']) => boolean;
  search?: string;
};

export const BoardSelectBoardListItem = memo(
  ({
    board,
    getBoardDisabledMessage,
    isBoardDisabled,
    isBoardExpanded,
    isBoardSelected,
    onBoardOpenChange,
    onSelectBoard,
    shouldShowSubBoards,
    search,
  }: BoardSelectBoardListItemProps) => {
    const isDisabled = isBoardDisabled?.(board);
    const isSelected = isBoardSelected?.(board);
    const isExpanded = isBoardExpanded?.(board.id);

    const [isCreatingSubBoard, setIsCreatingSubBoard] = useState(false);
    const { boardPermissions } = useBoardPermissions({ boardId: board.id });
    const { createNewBoard } = useCreateNewBoard();
    const {
      data: subBoards,
      isInitialLoading: isLoadingSubBoards,
      hasMore: hasMoreSubBoards,
      loadNextPage: loadNextSubBoardsPage,
    } = useSubBoardsList(board.id, Boolean(isExpanded), { search });

    useFetchObjectsPermissions({
      objects: {
        boardIds: subBoards.map((board) => board.id),
      },
    });

    const onDraftSubBoard = useCallback(() => {
      onBoardOpenChange({ id: board.id, isOpen: true });
      setIsCreatingSubBoard(true);
    }, [board.id, onBoardOpenChange]);

    const onSubBoardCreate = useCallback(
      (boardName: string) => {
        setIsCreatingSubBoard(false);

        if (boardName) {
          createNewBoard({
            board: {
              title: boardName,
              parentId: board.id,
              libraryId: board.library?.id,
            },
            trackLocation: 'board-select-modal',
            shouldFollow: true,
          });
        }
      },
      [board.id, board.library?.id, createNewBoard],
    );

    useEffect(() => {
      if (shouldShowSubBoards?.(board.id)) {
        onBoardOpenChange({ id: board.id, isOpen: true });
      }
    }, [board.id, onBoardOpenChange, shouldShowSubBoards]);

    return (
      <BoardSelectListItem
        disabled={isDisabled}
        disabledMessage={getBoardDisabledMessage?.(board)}
        id={board.id}
        isExpanded={isExpanded || isCreatingSubBoard}
        isSelected={isSelected}
        onOpenChange={(isOpen) => onBoardOpenChange({ id: board.id, isOpen })}
        onSelect={() => onSelectBoard?.(isSelected ? null : board)}
        suffix={
          !isDisabled &&
          canCreateBoard(boardPermissions) && (
            <Tooltip label={CREATE_SUB_BOARD_LABEL}>
              <IconButton
                appearance="ghost"
                className="hidden group-hover/treeItem:flex"
                color="grey"
                icon={Plus}
                label={CREATE_SUB_BOARD_LABEL}
                onClick={onDraftSubBoard}
                size="small"
              />
            </Tooltip>
          )
        }
        title={board.title}
      >
        {isCreatingSubBoard && (
          <BoardSelectCreateInput onBlur={() => setIsCreatingSubBoard(false)} onSubmit={onSubBoardCreate} />
        )}

        {subBoards.map((subBoard) => {
          return (
            <BoardSelectBoardListItem
              board={subBoard}
              getBoardDisabledMessage={getBoardDisabledMessage}
              isBoardDisabled={isBoardDisabled}
              isBoardExpanded={isBoardExpanded}
              isBoardSelected={isBoardSelected}
              key={subBoard.id}
              onBoardOpenChange={onBoardOpenChange}
              onSelectBoard={onSelectBoard}
              shouldShowSubBoards={shouldShowSubBoards}
              search={search}
            />
          );
        })}

        {!isCreatingSubBoard && isExpanded && !subBoards.length && !isLoadingSubBoards && (
          <Button appearance="ghost" className="mt-1" color="grey" onClick={onDraftSubBoard} size="small">
            {CREATE_SUB_BOARD_LABEL}
          </Button>
        )}

        {!isLoadingSubBoards && hasMoreSubBoards && (
          <Button className="mt-1" onClick={() => loadNextSubBoardsPage()} size="small" appearance="ghost" color="grey">
            Show more
          </Button>
        )}
      </BoardSelectListItem>
    );
  },
);

BoardSelectBoardListItem.displayName = 'BoardSelectBoardListItem';
