import { FileStatusButton, FileStatusIconErrored, FileStatusTitle } from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import pluralize from 'pluralize';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { GetErrorMetadata, ZippingTask } from '~/store/tasks/types';

export type ZippingTaskFailedPanelItemProps = {
  metadata: GetErrorMetadata<ZippingTask>;
  onClear: () => void;
};

export const ZippingTaskFailedPanelItem = memo(
  ({ metadata: { clipIds = [], boardIds = [] }, onClear }: ZippingTaskFailedPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<FileStatusIconErrored />}
        title={
          <FileStatusTitle>{`Failed to zip ${pluralize(
            'item',
            clipIds.length + boardIds.length,
            true,
          )}`}</FileStatusTitle>
        }
        buttons={
          <FileStatusButton label="Clear" onClick={() => onClear()}>
            <Close className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

ZippingTaskFailedPanelItem.displayName = 'ZippingTaskFailedPanelItem';
