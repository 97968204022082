import classNames from 'classnames';
import { type ComponentProps, forwardRef } from 'react';

export type ButtonGroupProps = ComponentProps<'div'>;

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  ({ className, ...restOfProps }, forwardedRef) => {
    return (
      <div
        className={classNames(
          'flex gap-px [&>*:first-child]:rounded-bl [&>*:first-child]:rounded-tl [&>*:last-child]:rounded-br [&>*:last-child]:rounded-tr [&>*]:rounded-none',
          className,
        )}
        {...restOfProps}
        ref={forwardedRef}
      />
    );
  },
);

ButtonGroup.displayName = 'ButtonGroup';
